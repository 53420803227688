import styled from 'styled-components';
import { pallette } from '../../config/pallette';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${pallette.white};
  border: 1px solid ${pallette.lightGray};
  border-radius: 5px;
  padding: 0 0;
  z-index: 9998;
`;

export const CircleCTA = styled.button`
  background-color: transparent;
  color: ${pallette.white};
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 100px;
  cursor: pointer;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LogoImage = styled.img`
  height: 70px;
  width: 70px;
`;

export const LogoText = styled.span`
  color: ${pallette.green};
  font-size: 24px;
`;

export const TopPanel = styled.div`
  width: 100%;
  background-color: ${pallette.lightPurple};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 34px;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  color: #666;
  border: none;
  height: 100%;
  cursor: pointer;
  align-self: flex-end;
`;

export const AllMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 350px;
  overflow: auto;
  margin: 5px;
  padding: 10px 10px;
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MessageContainerUser = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export const MessageContainerBot = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
`

export const MessageUser = styled.span`
  font-size: 16px;
  text-align: right;
  background-color: ${pallette.purple};
  color: ${pallette.white};
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  align-self: right;
  overflow: hidden;
`;

export const MessageBot = styled.span`
  font-size: 16px;
  color: ${pallette.text};
  text-align: left;
  background-color: ${pallette.lightGray};
  padding: 10px;
  border-radius: 10px;
  white-space: pre-line;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const MessageError = styled.span`
  font-size: 16px;
  color: ${pallette.white};
  text-align: left;
  background-color: ${pallette.error};
  padding: 10px;
  border-radius: 10px;
  white-space: pre-line;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${pallette.lightPurple};
`;

export const Input = styled.input`
  border: none;
  font-size: 16px;
  flex: 1;
  padding: 10px;
  background-color: ${pallette.lightPurple};
  color: ${pallette.text};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${pallette.purple};
  }
`;

export const SendButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
`;

export const RetryButton = styled.span`
  color: ${pallette.lightGray};
  font-weight: bold;
  cursor: pointer;
`;

export const TopicButton = styled.div`
  background-color: ${pallette.lightPurple};
  cursor: pointer;
  padding: 5px;
  margin: 5px;
`;
